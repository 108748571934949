// eslint-disable-next-line import/no-duplicates
import { add, format, getDaysInMonth, getDaysInYear, getYear } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { makeAutoObservable } from 'mobx';
import { registerLocale } from 'react-datepicker';

import { ICalculatorFilterElement } from '@/domain/calculator/AbstractCalculatorStore';
import RangeInput, { IRangeInput } from '@/domain/calculator/blocksStore/Range';

registerLocale('ru', ru);

export interface IDataItem {
    date?: string;
    monthlyPayment: number;
    monthlyDebt: number;
    monthlyPersent: number;
    monthlyBalance: number;
    reductionPayment?: number;
    reductionTerm?: number;
    activeYear?: boolean;
    tempValue?: number;
    addTermValue?: number;
    prevActiveYearMonthlyBalance?: number;
    yearValue?: number;
    dateValue?: number;
}

export interface IHypothecReadyHousingCalculatorStore {
    paymentValue: number;
    depositElements: IRangeInput;
    sumElements: IRangeInput;
    termsElements: IRangeInput;
    depositValue: number;
    creditValue: number;
    termValue: number;
    rate: number;
    configParams: {
        isSmall: boolean;
    };
    getRate?: () => number;
    handleChangeRate: (value: number) => void;
    cell: string;
    handleChangeCell: (value: string) => void;
    frequency: string;
    handleChangeFrequency: (value: string) => void;
    amountRepayment: number;
    handleChangeAmountRepayment: (value: number) => void;
    isSpecialActive?: boolean;
    toggleSpecialActive?: () => void;
    isInsurance?: boolean;
    toggleInsurance?: () => void;
    getDataChart?: (flag?: boolean) => Array<IDataItem>;
    getNalogSum?: () => number;
    handleChangeDepositElementsMinValue: (value: number) => void;
    handleChangeDepositElementsMaxValue: (value: number) => void;
    handleChangeSumElementsMaxValue: (value: number) => void;
    handleChangeSumElementsMinValue: (value: number) => void;
    initialized: boolean;
}

class HypothecReadyHousingCalculatorStore implements IHypothecReadyHousingCalculatorStore {
    public depositElements: IRangeInput = null;

    public sumElements: IRangeInput = null;

    public termsElements: IRangeInput = null;

    public rate = 0;

    public isSpecialActive = false;

    public isInsurance = true;

    public cell = '';

    public frequency = '';

    public amountRepayment = 0;

    public initialized = false;

    public configParams = {
        isSmall: false,
    };

    constructor() {
        makeAutoObservable(this);
    }

    get depositValue(): number {
        return this.depositElements.value;
    }

    get creditValue(): number {
        return this.sumElements.value;
    }

    get termValue(): number {
        return this.termsElements.value;
    }

    get paymentValue() {
        if (this.creditValue - this.depositValue < 0) {
            return 0;
        }

        const MONTHS_IN_YEAR = 12;
        const currentRate = this.getRate();
        const percentRate = currentRate / 100;

        let result = 0;

        // (Сумма кредита – первоначальный взнос)*( процентная ставка /12/(1-(1+ процентная ставка /12)^(- количество месяцев))

        const sum = this.creditValue - this.depositValue;

        const percentPart = (1 + percentRate / MONTHS_IN_YEAR) ** (0 - this.termValue * MONTHS_IN_YEAR);

        result = +((sum * percentRate) / MONTHS_IN_YEAR / (1 - percentPart)).toFixed(2);

        return result;
    }

    handleChangeRate = (value: number) => {
        this.rate = value;
    };

    handleChangeCell = (value: string) => {
        this.cell = value;
    };

    handleChangeFrequency = (value: string) => {
        this.frequency = value;
    };

    handleChangeAmountRepayment = (value: number) => {
        this.amountRepayment = value;
    };

    getRate = () => (this.isInsurance ? this.rate : this.rate + 2);

    public toggleSpecialActive = () => {
        this.isSpecialActive = !this.isSpecialActive;
    };

    public toggleInsurance = () => {
        this.isInsurance = !this.isInsurance;
    };

    getDataChart = (flag = false) => {
        const data: Array<IDataItem> = [];

        data.push({
            date: '',
            monthlyPayment: this.paymentValue,
            monthlyDebt: 0,
            monthlyPersent: 0,
            monthlyBalance: this.creditValue - this.depositValue,
            reductionPayment: 0,
            reductionTerm: 0,
            activeYear: false,
            tempValue: 4,
            addTermValue: this.termValue * 12,
            yearValue: 0,
            dateValue: +new Date(),
        });

        let i = 1;
        let result = 0;
        do {
            const nextDate = add(new Date(), { months: i });

            const currentDateMonth = add(new Date(), { months: i - 1 });
            const currentDateYear = add(new Date(), { months: i });

            const currentRate = this.getRate();
            const percentRate = currentRate / 100;

            let activeYear: boolean;

            if (this.frequency === 'Разовый платеж') {
                activeYear = i === 1;
            } else if (this.frequency === 'Раз в месяц') {
                activeYear = true;
            } else if (this.frequency === 'Раз в 3 месяца') {
                activeYear = !((i + 1) % 3);
            } else if (this.frequency === 'Раз в полгода') {
                activeYear = !((i + 1) % 6);
            } else if (this.frequency === 'Раз в год') {
                activeYear = !((i + 1) % 12);
            }

            const activeYearDataList = data?.filter(item => item.activeYear === true);

            const prevActiveYearMonthlyBalance = activeYearDataList[activeYearDataList.length - 1]?.monthlyBalance;

            const tempValue = !data[i - 1].activeYear ? data[i - 1].tempValue : i - 1 + data[0].tempValue;

            const tempAddTermValue = tempValue === data[i - 1].tempValue ? 0 : tempValue - data[i - 1].tempValue;
            const addTermValue = data[i - 1].addTermValue - (this.amountRepayment > 0 ? tempAddTermValue : 0);

            /// ////// рассчет ежемесячного платежа
            /// ////// flag нужен, чтобы посчитать Экономию (там необходимы 2 вида расчетов ежем платежа одновременно - со спец условиями и без)
            /// ////// flag - без спец условий
            if (flag) {
                const sum = this.creditValue - this.depositValue;

                const percentPart = (1 + percentRate / 12) ** (0 - this.termValue * 12);

                result = +((sum * percentRate) / 12 / (1 - percentPart)).toFixed(2);
            } else if (this.cell === 'Платеж в месяц') {
                const percentPart = (1 + percentRate / 12) ** (0 - addTermValue);

                result = +(
                    ((prevActiveYearMonthlyBalance || this.creditValue - this.depositValue) * percentRate) /
                        12 /
                        (1 - percentPart) +
                    (activeYear ? this.amountRepayment : 0)
                ).toFixed(2);
            } else if (this.cell === 'Срок кредита') {
                const percentPart = (1 + percentRate / 12) ** (0 - this.termValue * 12);

                result = +(
                    ((data[0].monthlyBalance || this.creditValue - this.depositValue) * percentRate) /
                        12 /
                        (1 - percentPart) +
                    (activeYear ? this.amountRepayment : 0)
                ).toFixed(2);
            } else {
                const sum = this.creditValue - this.depositValue;

                const percentPart = (1 + percentRate / 12) ** (0 - this.termValue * 12);

                result = +((sum * percentRate) / 12 / (1 - percentPart)).toFixed(2);
            }
            /// ///////////////

            /// /// занесение данных во вспомомгательную таблицу

            /// /// для последнего месяца
            if (data[i - 1].monthlyBalance < result) {
                // /// делаем дополнительный временный массив, чтобы правильно досчитать все строки, а потом значения
                // /// последней строки добавляем в предпоследнюю
                const temp: Array<IDataItem> = [];

                temp.push({
                    date: format(nextDate, 'MMM yyyy', { locale: ru }),

                    monthlyPayment: +(
                        data[i - 1].monthlyBalance +
                        (data[i - 1].monthlyBalance * percentRate * getDaysInMonth(currentDateMonth)) /
                            getDaysInYear(currentDateYear)
                    ).toFixed(2),

                    monthlyDebt: +(+data[i - 1].monthlyBalance).toFixed(2),

                    monthlyPersent: +(
                        (data[i - 1].monthlyBalance * percentRate * getDaysInMonth(currentDateMonth)) /
                        getDaysInYear(currentDateYear)
                    ).toFixed(2),

                    monthlyBalance: 0,

                    reductionPayment: this.isSpecialActive && this.cell === 'Платеж в месяц' ? this.amountRepayment : 0,

                    reductionTerm: this.isSpecialActive && this.cell === 'Срок кредита' ? this.amountRepayment : 0,

                    activeYear,
                    yearValue: getYear(currentDateYear),
                    dateValue: +nextDate,
                });

                data[i - 1].monthlyPayment = +(+data[i - 1].monthlyPayment + +temp[0].monthlyPayment).toFixed(2);
                data[i - 1].monthlyDebt = +(+data[i - 1].monthlyDebt + +temp[0].monthlyDebt).toFixed(2);
                data[i - 1].monthlyPersent = +(+data[i - 1].monthlyPersent + +temp[0].monthlyPersent).toFixed(2);
                data[i - 1].monthlyBalance = 0;

                break;
            }
            /// /// для обычных месяцев
            else {
                data.push({
                    date: format(nextDate, 'MMM yyyy', { locale: ru }),

                    monthlyPayment: result,

                    monthlyDebt: +(
                        result -
                        (data[i - 1].monthlyBalance * percentRate * getDaysInMonth(currentDateMonth)) /
                            getDaysInYear(currentDateYear)
                    ).toFixed(2),

                    monthlyPersent: +(
                        (data[i - 1].monthlyBalance * percentRate * getDaysInMonth(currentDateMonth)) /
                        getDaysInYear(currentDateYear)
                    ).toFixed(2),

                    monthlyBalance: +(
                        data[i - 1].monthlyBalance -
                        (result -
                            (data[i - 1].monthlyBalance * percentRate * getDaysInMonth(currentDateMonth)) /
                                getDaysInYear(currentDateYear))
                    ).toFixed(2),

                    reductionPayment: this.isSpecialActive && this.cell === 'Платеж в месяц' ? this.amountRepayment : 0,

                    reductionTerm: this.isSpecialActive && this.cell === 'Срок кредита' ? this.amountRepayment : 0,

                    activeYear,
                    yearValue: getYear(currentDateYear),
                    tempValue,
                    addTermValue,
                    prevActiveYearMonthlyBalance: prevActiveYearMonthlyBalance || this.creditValue - this.depositValue,
                    dateValue: +nextDate,
                });
            }

            // eslint-disable-next-line no-plusplus
            i++;
        } while (data[i - 1].monthlyBalance > 0);

        return data;
    };

    getNalogSum = () => {
        if (this.creditValue - this.depositValue < 0) {
            return 0;
        }

        const SUM = 650000;

        const temp1 = (this.creditValue - this.depositValue) * 0.13;
        const temp2 = this.getDataChart().reduce((acc, item) => acc + item.monthlyPersent, 0) * 0.13;

        const value = temp1 + temp2 > SUM ? SUM : temp1 + temp2;

        return +value.toFixed(2);
    };

    handleChangeDepositElementsMinValue = (value: number) => {
        this.depositElements.handleChangeMinValue({ value });
    };

    handleChangeDepositElementsMaxValue = (value: number) => {
        this.depositElements.handleChangeMaxValue({ value });
    };

    // Обработчик для ограничения ползунка на максимальной сумме кредита в зависимости от региона
    handleChangeSumElementsMaxValue = (value: number) => {
        this.sumElements.handleChangeMaxValue({ value });
    };

    // Обработчик для ограничения ползунка на минимальной сумме кредита
    handleChangeSumElementsMinValue = (value: number) => {
        this.sumElements.handleChangeMinValue({ value });
    };

    public init = (
        depositElements: ICalculatorFilterElement[],
        sumElements: ICalculatorFilterElement[],
        termsElements: ICalculatorFilterElement[],
        rate: number
    ) => {
        const termsElementsName = 'Срок кредита';

        this.depositElements = new RangeInput({
            name: 'depositValue',
            elements: depositElements,
            initValue: 1000000,
        });

        this.sumElements = new RangeInput({
            name: 'creditValue',
            elements: sumElements,
            initValue: 5000000,
        });

        this.termsElements = new RangeInput({
            name: 'termValue',
            title: termsElementsName,
            label: 'Укажите срок',
            elements: termsElements,
            initValue: 25,
            customFormatValueHandle: (value, _) => `${value}`,
        });

        this.rate = rate;

        this.initialized = true;
    };
}

export default new HypothecReadyHousingCalculatorStore();
