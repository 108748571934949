import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import Counter from '@/components/blocks/calculators/layouts/HypothecCalculator/Counter';
import HypothecReadyHousingCalculatorModalContent from '@/components/blocks/calculators/layouts/HypothecReadyHousingCalculator/ModalContent';
import RangeInput from '@/components/blocks/calculators/range/RangeInput';
import ModalWrapper from '@/components/modal/ModalWrapper';
import CustomTooltip from '@/components/Tooltip';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import CustomSelect from '@/components/UI/CustomSelect';
import Title from '@/components/UI/Title';
import TooltipIcon from '@/components/UI/TooltipIcon';
import TooltipText from '@/components/UI/TooltipText';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { useCity } from '@/context/CityProvider';
import { IHypothecReadyHousingCalculatorStore } from '@/domain/calculator/HypothecReadyHousingCalculatorStore';
import {
    THypothecReadyHousingCalculatorConfigItem,
    THypothecReadyHousingCalculatorConfigRatesItem,
    THypothecReadyHousingCalculatorContent,
} from '@/types/calculator/hypothecReadyHousingCalc';
import { ISelectElement } from '@/types/select';
import { finFormattingValue } from '@/utils/finFormattingValue';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const HypothecReadyHousingCalculatorContent: FC<THypothecReadyHousingCalculatorContent> = observer(
    ({ title, config }) => {
        const {
            termsElements,
            depositElements,
            sumElements,
            creditValue,
            getRate,
            handleChangeRate,
            paymentValue,
            toggleSpecialActive,
            isSpecialActive,
            toggleInsurance,
            isInsurance,
            handleChangeAmountRepayment,
            handleChangeFrequency,
            handleChangeCell,
            getNalogSum,
            handleChangeDepositElementsMinValue,
            handleChangeDepositElementsMaxValue,
            handleChangeSumElementsMinValue,
            termValue,
            depositValue,
            configParams,
        } = useContext<IHypothecReadyHousingCalculatorStore>(CalculatorStoreContext);

        const { currentCity } = useCity();

        const [currentConfig, setCurrentConfig] = useState<THypothecReadyHousingCalculatorConfigItem>(config[0]);

        const [currentRatesItem, setCurrentRatesItem] = useState<THypothecReadyHousingCalculatorConfigRatesItem>(
            config[0].rates[0]
        );

        const [minDepositValue, setMinDepositValue] = useState((creditValue * currentRatesItem.firstPayment) / 100);

        const [maxDepositValue, setMaxDepositValue] = useState(creditValue * 0.9);

        const [currentMinCreditValue, setCurrentMinCreditValue] = useState<number>(null);

        const [typeProgramValue, setTypeProgramValue] = useState({
            label: currentConfig.rates[0].title,
            value: currentConfig.rates[0].title,
            desc: currentConfig.rates[0].desc,
        });

        const optionsRates = [];
        currentConfig.rates.forEach(item => {
            optionsRates.push({ label: `${item.title}`, value: item.title, desc: item?.desc });
        });

        const [isModalOpen, setIsModalOpen] = useState(false);

        const handleOpen = () => {
            setIsModalOpen(true);
        };

        const needFieldFullCoast = useMemo(
            () => !(!currentRatesItem?.fullCreditCoastMin || !currentRatesItem?.fullCreditCoastMax),
            [currentRatesItem]
        );

        useEffect(() => {
            // Минимально возможная сумма кредита
            const currentMinValue = currentConfig?.minCreditValue || 300000;

            // Минимально возможная стоимость недвижимости
            const tempMinCreditValue = Math.round(
                currentMinValue +
                    (currentMinValue * (currentRatesItem.firstPayment / 100)) /
                        ((100 - currentRatesItem.firstPayment) / 100)
            );

            handleChangeSumElementsMinValue(tempMinCreditValue);
            setCurrentMinCreditValue(tempMinCreditValue);
        }, [currentConfig.minCreditValue, currentRatesItem]);

        useEffect(() => {
            // Максимально возможная сумма первоначального взноса - чтобы сумма ипотеки составила хотя бы 300 000 - minCreditValue

            const maxCreditValue = creditValue - (currentConfig?.minCreditValue || 300000);

            if (maxDepositValue < maxCreditValue || maxDepositValue >= creditValue) {
                setMaxDepositValue(maxCreditValue);
                handleChangeDepositElementsMaxValue(maxCreditValue);
            }
        }, [creditValue, currentConfig?.minCreditValue]);

        useEffect(() => {
            // Минимально возможная сумма первоначального взноса в зависимости от суммы ипотеки -
            // заданный процент, либо чтобы сумма кредита не превысила лимит

            const tempMinDeposit = Math.round(creditValue * (currentRatesItem.firstPayment / 100));

            if (creditValue <= currentMinCreditValue) {
                // 300 000 - minCreditValue - минимально возможная сумма кредита

                handleChangeDepositElementsMinValue(tempMinDeposit);
                setMinDepositValue(tempMinDeposit);
                setMaxDepositValue(tempMinDeposit);
                handleChangeDepositElementsMaxValue(tempMinDeposit);
            } else if (tempMinDeposit > creditValue - currentConfig.regionsMax) {
                handleChangeDepositElementsMinValue(tempMinDeposit);
                setMinDepositValue(tempMinDeposit);
            } else {
                handleChangeDepositElementsMinValue(creditValue - currentConfig.regionsMax);
                setMinDepositValue(creditValue - currentConfig.regionsMax);
            }
        }, [creditValue, depositValue, currentRatesItem, currentConfig]);

        const uuidUser = useMemo(() => v4(), []);

        const specialLink = `https://my.pochtabank.ru/pos-credit-v2?operId=${uuidUser}&productCode=MORTGAGE_IGS_${
            currentRatesItem?.code || 'HOUSE'
        }${isInsurance ? '_INS' : ''}&toCode=9999999998&ttCode=999999999821&ttName=&amountCredit&termCredit=${
            termValue * 12
        }&firstPayment=${depositValue}&fullName=&phone=&brokerAgentId=NON_BROKER&returnUrl=&order%5B0%5D%5Bcategory%5D=274&order%5B0%5D%5Bmark%5D=%D0%94%D0%BE%D0%BC&order%5B0%5D%5Bmodel%5D=%D0%9A%D0%B8%D1%80%D0%BF%D0%B8%D1%87%D0%BD%D1%8B%D0%B9&order%5B0%5D%5Bquantity%5D=1&order%5B0%5D%5Bprice%5D=${creditValue}&_gl=1*swyk6d*_gcl_au*MTcwMDgxNTE0NC4xNzEwMjQ2MDE0&_ga=2.172506851.1174157208.1711719003-1102399943.1701851466`;

        return (
            <div className="section">
                <div className={cn.fullWrapper}>
                    <div className={cn.content}>
                        <div className={cn.left}>
                            <div className={cn.wrapper}>
                                <Title title={currentConfig.target} level={2} />
                                <div className={cn.ratesWrapper}>
                                    <div className={cn.wrapperTop}>Тип программы</div>
                                    <CustomSelect
                                        placeholder="Тип жилья"
                                        value={typeProgramValue}
                                        options={optionsRates}
                                        customOptionFlag
                                        onChange={(e: ISelectElement<string>) => {
                                            setTypeProgramValue({
                                                label: e.label,
                                                value: e.value,
                                                desc: e?.desc,
                                            });

                                            const current = currentConfig.rates.find(item => item.title === e.label);

                                            setCurrentRatesItem(current);

                                            handleChangeRate(current.rateValue);
                                        }}
                                    />
                                </div>

                                <div className={cn.firstPayment}>
                                    <span>Стоимость недвижимости</span>

                                    {currentConfig?.tooltipText && (
                                        <div className={cn.tooltip}>
                                            <CustomTooltip
                                                html={<TooltipText tooltipText={currentConfig.tooltipText} />}
                                            >
                                                <TooltipIcon />
                                            </CustomTooltip>
                                        </div>
                                    )}
                                </div>
                                <RangeInput
                                    config={sumElements}
                                    minValue={currentConfig?.minCreditValue || 500000}
                                    noMaxWidth
                                />
                                <div className={cn.mark}>
                                    Вы можете взять кредит до
                                    {` ${finFormattingValue(currentConfig.regionsMax)}`}
                                    &nbsp;₽
                                </div>

                                <div className={cn.firstPayment}>
                                    Первоначальный взнос от {currentRatesItem.firstPayment}%
                                </div>
                                <RangeInput
                                    config={depositElements}
                                    minValue={minDepositValue}
                                    maxValue={maxDepositValue}
                                    noMaxWidth
                                />
                                <div className={cn.mark}>
                                    Вы можете выбрать сумму первоначального взноса от
                                    {` ${finFormattingValue(minDepositValue)}`}
                                    &nbsp;₽
                                </div>

                                <RangeInput config={termsElements} noMaxWidth />

                                <div className={cn.bottom}>
                                    <Checkbox
                                        label="Страхование жизни и здоровья заемщика"
                                        checked={isInsurance}
                                        onChangeHandler={() => toggleInsurance()}
                                        customClass={clsx(cn.checkbox, 'hypothecCheckbox')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={cn.right}>
                            <div className={cn.rightWrapper}>
                                <div className={cn.wrapResult}>
                                    <div className={cn.row}>
                                        <div className={clsx(cn.rowItem, cn.rowItem70)}>
                                            <div className={cn.resultText}>Сумма ежемесячного платежа</div>
                                            <div className={cn.resultNumber}>
                                                <Counter
                                                    paymentValue={finFormattingValue(Math.round(paymentValue))}
                                                    field={termsElements}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={cn.row}>
                                        <div className={cn.rowItem}>
                                            <div className={cn.resultText}>Сумма кредита</div>
                                            <div
                                                className={cn.resultNumber}
                                                dangerouslySetInnerHTML={{
                                                    __html: `${finFormattingValue(
                                                        creditValue - depositValue > 0 ? creditValue - depositValue : 0
                                                    )}&nbsp;₽`,
                                                }}
                                            />
                                        </div>
                                        <div className={clsx(cn.rowItem, cn.rowItem30)}>
                                            <div className={cn.resultText}>Ставка</div>
                                            <div className={cn.resultNumber}>{getRate()}%</div>
                                        </div>
                                    </div>

                                    {needFieldFullCoast && (
                                        <div className={cn.row}>
                                            <div className={clsx(cn.rowItem)}>
                                                <div className={cn.resultText}>Полная стоимость кредита (диапазон)</div>
                                                <div className={cn.resultNumber}>
                                                    {currentRatesItem.fullCreditCoastMin.toFixed(3)}% -{' '}
                                                    {currentRatesItem.fullCreditCoastMax.toFixed(3)}%
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={cn.row}>
                                        <div className={cn.rowItem}>
                                            <div className={cn.resultText}>Совокупный доход</div>
                                            <div
                                                className={cn.resultNumber}
                                                dangerouslySetInnerHTML={{
                                                    __html: `${finFormattingValue(
                                                        Math.round((paymentValue * 100) / 60)
                                                    )}&nbsp;₽`,
                                                }}
                                            />
                                        </div>
                                        <div className={clsx(cn.rowItem, cn.rowItem40)}>
                                            <div className={cn.resultText}>Налоговый вычет</div>
                                            <div
                                                className={cn.resultNumber}
                                                dangerouslySetInnerHTML={{
                                                    __html: `${finFormattingValue(Math.round(getNalogSum()))}&nbsp;₽`,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={cn.buttonWrapper}>
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={specialLink || currentConfig?.button?.link}
                                            label={currentConfig?.button?.label}
                                            size="small"
                                            customClass={cn.button}
                                            onClick={() => {
                                                if (currentConfig?.gtag?.action) event(currentConfig.gtag);
                                            }}
                                            animation={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalWrapper
                    isOpen={isModalOpen}
                    handleClose={() => {
                        setIsModalOpen(false);
                        if (isSpecialActive) {
                            toggleSpecialActive();
                            handleChangeCell('');
                            handleChangeFrequency('');
                            handleChangeAmountRepayment(0);
                        }
                    }}
                    handleOpen={handleOpen}
                    wrapperClassName="modalBigWrapper"
                    bgClassName={cn.modalOverlay}
                    className={cn.modalInner}
                    clickOutsideExceptionsId={['modalWrapper']}
                >
                    <HypothecReadyHousingCalculatorModalContent currentConfig={currentConfig} />
                </ModalWrapper>
            </div>
        );
    }
);

export default HypothecReadyHousingCalculatorContent;
