import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import HypothecReadyHousingCalculatorContent from '@/components/blocks/calculators/layouts/HypothecReadyHousingCalculator/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import HypothecReadyHousingCalculatorStore from '@/domain/calculator/HypothecReadyHousingCalculatorStore';
import Feature from '@/domain/feature/Feature';
import { THypothecReadyHousingCalculator } from '@/types/calculator/hypothecReadyHousingCalc';

const HypothecReadyHousingCalculator: React.FC<THypothecReadyHousingCalculator> = observer(({ title, config }) => {
    if (!config || config?.length <= 0) {
        return null;
    }

    useEffect(() => {
        const element = document.querySelector('.layoutMiddle');
        if (element) {
            element.classList.add('disable-overflow');
        }
    }, []);

    useEffect(() => {
        HypothecReadyHousingCalculatorStore.init(
            config[0].depositElements,
            config[0].sumElements,
            config[0].termsElements,
            config[0].rates[0].rateValue
        );
    }, []);

    if (!Feature.isFeatureEnabled('enableHypothecReadyHousingCalculator')) {
        return null;
    }

    return (
        <CalculatorStoreContext.Provider value={HypothecReadyHousingCalculatorStore}>
            {HypothecReadyHousingCalculatorStore.initialized && (
                <HypothecReadyHousingCalculatorContent title={title} config={config} />
            )}
        </CalculatorStoreContext.Provider>
    );
});

export default withBlockLinks(HypothecReadyHousingCalculator);
