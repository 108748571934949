// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';

import HypothecReadyHousingCalculatorChart from '@/components/blocks/calculators/layouts/HypothecReadyHousingCalculator/Chart';
import HypothecReadyHousingCalculatorInfo from '@/components/blocks/calculators/layouts/HypothecReadyHousingCalculator/Info';
import IconLogo from '@/components/UI/IconLogo';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IHypothecReadyHousingCalculatorStore } from '@/domain/calculator/HypothecReadyHousingCalculatorStore';
import { THypothecReadyHousingCalculatorConfigItem } from '@/types/calculator/hypothecReadyHousingCalc';

import cn from './style.module.sass';

interface IProps {
    currentConfig: THypothecReadyHousingCalculatorConfigItem;
}

function DownloadChart({ currentConfig }: IProps) {
    const { paymentValue } = useContext<IHypothecReadyHousingCalculatorStore>(CalculatorStoreContext);

    if (paymentValue <= 0) return null;

    return (
        <div className={cn.wrapper}>
            <div className="logo">
                <IconLogo />
            </div>
            <div className={cn.title}>
                График платежей по ипотеке от {format(new Date(), 'dd MMMM yyyy', { locale: ru })}
            </div>
            <HypothecReadyHousingCalculatorInfo currentConfig={currentConfig} />
            <HypothecReadyHousingCalculatorChart />
        </div>
    );
}
export default observer(DownloadChart);
