import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import HypothecReadyHousingCalculatorChart from '@/components/blocks/calculators/layouts/HypothecReadyHousingCalculator/Chart';
import Button from '@/components/UI/Button';

import cn from './style.module.sass';

function HypothecReadyHousingCalculatorChartWrapper() {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={cn.wrapper}>
            {!isActive ? (
                <div className={cn.head}>
                    <Button
                        variant="btnBlue"
                        type="button"
                        label="Показать график платежей"
                        size="small"
                        customClass={cn.button}
                        onClick={() => {
                            setIsActive(true);
                        }}
                        animation={false}
                    />
                </div>
            ) : (
                <div className={cn.body}>
                    <HypothecReadyHousingCalculatorChart />
                </div>
            )}
        </div>
    );
}

export default observer(HypothecReadyHousingCalculatorChartWrapper);
