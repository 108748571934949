// eslint-disable-next-line import/no-duplicates
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { registerLocale } from 'react-datepicker';

import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IHypothecReadyHousingCalculatorStore } from '@/domain/calculator/HypothecReadyHousingCalculatorStore';

import cn from './style.module.sass';

registerLocale('ru', ru);

interface IProps {
    countToView?: number;
}

function HypothecReadyHousingCalculatorChart({ countToView }: IProps) {
    const { getDataChart } = useContext<IHypothecReadyHousingCalculatorStore>(CalculatorStoreContext);

    const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return (
        <div className={cn.wrapper}>
            <div className={cn.head}>
                <div className={cn.item}>Дата</div>
                <div className={cn.item}>Платеж в месяц</div>
                <div className={cn.item}>Основной долг</div>
                <div className={cn.item}>Проценты</div>
                <div className={cn.item}>Остаток долга</div>
            </div>

            <div className={cn.body}>
                {getDataChart().map((item, index) => {
                    if (index === 0) return null;
                    if (index > countToView) return null;
                    return (
                        <div className={cn.row} key={`row-${index}`}>
                            <div className={cn.item}>{item.date}</div>

                            <div className={cn.item}>
                                <div className={cn.itemTitle}>Платеж в месяц</div>
                                <div className={cn.itemDec} />
                                <div className={cn.itemValue}>{`${formatValue(item.monthlyPayment)} ₽`}</div>
                            </div>

                            <div className={cn.item}>
                                <div className={cn.itemTitle}>Основной долг</div>
                                <div className={cn.itemDec} />
                                <div className={cn.itemValue}>{`${formatValue(item.monthlyDebt)} ₽`}</div>
                            </div>

                            <div className={cn.item}>
                                <div className={cn.itemTitle}>Проценты</div>
                                <div className={cn.itemDec} />
                                <div className={cn.itemValue}>{`${formatValue(item.monthlyPersent)} ₽`}</div>
                            </div>
                            <div className={cn.item}>
                                <div className={cn.itemTitle}>Остаток долга</div>
                                <div className={cn.itemDec} />
                                <div className={cn.itemValue}>{`${formatValue(item.monthlyBalance)} ₽`}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default observer(HypothecReadyHousingCalculatorChart);
