import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext, useState } from 'react';

import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import CustomSelect from '@/components/UI/CustomSelect';
import IconCart from '@/components/UI/IconCart';
import Input from '@/components/UI/Input';
import CalculatorStoreContext from '@/context/CalculatorStoreContextNew';
import { IHypothecReadyHousingCalculatorStore } from '@/domain/calculator/HypothecReadyHousingCalculatorStore';
import { THypothecReadyHousingCalculatorConfigItem } from '@/types/calculator/hypothecReadyHousingCalc';
import { ISelectElement } from '@/types/select';
import { finUnFormattingValue } from '@/utils/finUnFormattingValue';

import cn from './style.module.sass';

const frequencyOptions = [
    {
        label: 'Разовый платеж',
        value: 'Разовый платеж',
    },
    {
        label: 'Раз в месяц',
        value: 'Раз в месяц',
    },
    {
        label: 'Раз в 3 месяца',
        value: 'Раз в 3 месяца',
    },
    {
        label: 'Раз в полгода',
        value: 'Раз в полгода',
    },
    {
        label: 'Раз в год',
        value: 'Раз в год',
    },
];

const cellOptions = [
    {
        value: 'Платеж в месяц',
        label: 'Платеж в месяц',
    },
];

interface IProps {
    currentConfig: THypothecReadyHousingCalculatorConfigItem;
}

function HypothecReadyHousingCalculatorSpecial({ currentConfig }: IProps) {
    const {
        isSpecialActive,
        toggleSpecialActive,
        handleChangeAmountRepayment,
        handleChangeFrequency,
        handleChangeCell,
        getDataChart,
    } = useContext<IHypothecReadyHousingCalculatorStore>(CalculatorStoreContext);

    const formatValue = (value: number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const [cellValue, setCellValue] = useState<ISelectElement<string>>(null);
    const [frequencyValue, setFrequencyValue] = useState<ISelectElement<string>>(null);
    const [amountRepaymentValue, setAmountRepaymentValue] = useState<number>(0);

    const monthlyPersentSumWithoutSpec = getDataChart(true).reduce((acc, item) => acc + item.monthlyPersent, 0);
    const monthlyDebtSumWithoutSpec = getDataChart(true).reduce((acc, item) => acc + item.monthlyDebt, 0);
    const sumWithoutSpec = monthlyDebtSumWithoutSpec + monthlyPersentSumWithoutSpec;

    const monthlyPersentSumWithSpec = getDataChart().reduce((acc, item) => acc + item.monthlyPersent, 0);
    const monthlyDebtSumWithSpec = getDataChart().reduce((acc, item) => acc + item.monthlyDebt, 0);
    const sumWithSpec = monthlyDebtSumWithSpec + monthlyPersentSumWithSpec;

    const saving = sumWithoutSpec - sumWithSpec;

    return (
        <div className={cn.wrapper}>
            <div className={cn.head}>
                <Checkbox
                    label="Учесть досрочное погашение"
                    checked={isSpecialActive}
                    onChangeHandler={toggleSpecialActive}
                    customClass={clsx(cn.checkbox, 'hypothecCheckbox')}
                />

                {isSpecialActive && (
                    <div
                        className={clsx(cn.crossIcon, 'crossIcon')}
                        onClick={() => {
                            if (isSpecialActive) {
                                toggleSpecialActive();
                                handleChangeCell('');
                                handleChangeFrequency('');
                                handleChangeAmountRepayment(0);
                            }
                        }}
                        role="presentation"
                    >
                        <span className={clsx(cn.cross, 'cross')} />
                    </div>
                )}
            </div>
            {isSpecialActive && (
                <div className={cn.content}>
                    <div className={cn.row}>
                        <div className={cn.item}>
                            <div className={cn.itemTop}>Цель досрочного платежа</div>
                            <CustomSelect
                                placeholder="Хочу уменьшить"
                                value={cellValue}
                                onChange={(e: ISelectElement<string>) =>
                                    setCellValue({ label: e.value, value: e.value })
                                }
                                options={cellOptions}
                            />
                        </div>
                        <div className={cn.item}>
                            <div className={cn.itemTop}>Частота платежа</div>
                            <CustomSelect
                                placeholder="Буду вносить"
                                value={frequencyValue}
                                onChange={(e: ISelectElement<string>) =>
                                    setFrequencyValue({ label: e.value, value: e.value })
                                }
                                options={frequencyOptions}
                            />
                        </div>
                        <div className={cn.item}>
                            <div className={cn.itemTop}>
                                Сумма <span>(Дополнительная сумма без учета ежемесячного платежа)</span>
                            </div>
                            <Input
                                type="text"
                                customClass="typeNumber"
                                label="Сумма"
                                onChangeHandler={(e: ChangeEvent<HTMLInputElement>) => {
                                    setAmountRepaymentValue(+finUnFormattingValue(e.target.value));
                                }}
                                isFin
                            />
                        </div>
                    </div>
                    <div className={cn.buttonWrapper}>
                        <Button
                            variant="btnBlue"
                            type="button"
                            label="Добавить"
                            size="full"
                            customClass={cn.button}
                            disable={!cellValue || !frequencyValue || !amountRepaymentValue}
                            onClick={() => {
                                handleChangeCell(cellValue.value);
                                handleChangeFrequency(frequencyValue.value);
                                handleChangeAmountRepayment(amountRepaymentValue);
                            }}
                            animation={false}
                        />
                    </div>
                    <div className={cn.info}>
                        <div className={cn.infoText}>
                            Вы сэкономите <span> за весь срок</span>
                        </div>
                        <div className={cn.infoValue}>
                            <span>{`${formatValue(+saving.toFixed(2))} ₽`}</span>
                            <div
                                role="presentation"
                                className={cn.infoValueIcon}
                                onClick={() => {
                                    if (isSpecialActive) {
                                        toggleSpecialActive();
                                        handleChangeCell('');
                                        handleChangeFrequency('');
                                        handleChangeAmountRepayment(0);
                                    }
                                }}
                            >
                                <IconCart />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default observer(HypothecReadyHousingCalculatorSpecial);
